export const AVAILABLE_REGIONS: string[] = [
  "Czech Republic",
  "Poland",
  "Finland",
  "Italy"
]

export interface IRegion {
  name: string;
  code: string;
}

//{
//     name: "Czech Republic",
//     code: "CZ",
//   },
//   {
//     name: "Poland",
//     code: "PL",
//   },
//   {
//     name: "Italy",
//     code: "IT",
//   },
//   {
//     name: "Finland",
//     code: "FI",
//   },
