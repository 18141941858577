import axios, { AxiosInstance } from "axios";

export const client: AxiosInstance = axios.create({
  baseURL: "https://envirio.me:8080",
  proxy: false,
  timeout: 3000,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});
