import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../index";
import { ContentLoading, initialContentLoading } from "../commonTypes";
import { client } from "../../api/AxiosInstance";
import { IPanel, IRootObject, IRootPanelData } from '../../content/dashboards/Products/types';

export interface IPanelData {
  data?: ContentLoading<null | IRootPanelData>;
}

const initialState: IPanelData = { data: initialContentLoading(null) };

const panelSlice = createSlice({
  name: "panelSlice",
  initialState,
  reducers: {
    startGetPanel(state) {
      state.data = {
        content: null,
        status: "loading",
        error: undefined,
      };
    },
    setPanelData(state, action: PayloadAction<IRootPanelData>) {
      state.data = {
        content: action.payload,
        status: "loaded",
        error: undefined,
      };
    },
    setError(state, action: PayloadAction<Error>) {
      state.data = {
        content: null,
        status: "error",
        error: action.payload,
      };
    },
    addPanel(state, action: PayloadAction<IPanel>) {
      state.data.content.data = [...state.data.content.data, action.payload];
    },
    changePanel(state, action: PayloadAction<IPanel>) {
      state.data.content.data =
        state.data.content.data.map((item) =>
          action.payload.id === item.id ? action.payload : item
        );
    },
    deletePanelData(state, action: PayloadAction<number>) {
      state.data.content.data =
        state.data.content.data.filter((item) =>
          !(action.payload === item.id)
        );
    },
  },
});

const { startGetPanel, setPanelData, setError, addPanel, changePanel, deletePanelData } = panelSlice.actions;
export default panelSlice.reducer;

export const getPanelData =
  (distributorId: number) => async (dispatch: AppDispatch) => {
    dispatch(startGetPanel());
    try {
      const panelData = await client.get<IRootPanelData>(
        "/api/egc/getAllEgc",  { params: { id: distributorId } }
      );
      console.log("panelData", panelData);
      if (!panelData.data) {
        const err = new Error("Wrong response body");
        dispatch(setError(err));
      }
      // panelData.data.data = sortPanelsByEfficiency(panelData.data.data);
      dispatch(setPanelData(panelData.data));
    } catch (e) {
      if (typeof e === "string") {
        const err = new Error(e);
        dispatch(setError(err));
      } else if (e instanceof Error) {
        dispatch(setError(e));
      }
    }
  };

type PanelActionType = "edit" | "add";

const setPicture = async (image: FormData) => {
  if (image) {
    const imagePath = await client.post<IRootObject<string>>("/storage/image",
      image,
      {headers: {"Content-Type": "multipart/form-data",}}
    );
    const picturePath =  imagePath.data.data;
    if (picturePath) {
      return `https://envirio.me:8080/storage/image/${picturePath}`;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export const editOrAddPanel =
  (panel: IPanel, type: PanelActionType = "add", image?: FormData) => async (dispatch: AppDispatch) => {
    try {
      if (type === "edit") {
        const picturePath = await setPicture(image);
        if (picturePath) {
          panel.picture = picturePath;
        }
        const editPanelResponse = await client.post<IPanel>(
          "/api/egc/editEgc",
          panel
        );
        if (editPanelResponse.status === 200) {
          dispatch(changePanel(panel));
        }
      } else {
        const picturePath = await setPicture(image);
        if (picturePath) {
          panel.picture = picturePath;
        }
        const addPanelResponse =  await client.post<IRootObject<IPanel>>(
          "/api/egc/addEgc",
          panel
        );
        const uniqueNewPanel = addPanelResponse.data.data;
        if (addPanelResponse.status === 200) {
          dispatch(addPanel(uniqueNewPanel));
        }
      }
    } catch (e) {
    }
  };

export const deletePanel =
  (panelId: number) => async (dispatch: AppDispatch) => {
    try {
      const panelData = await client.post<IRootObject<boolean>>(
        "/api/egc/deleteEgc", {}, { params: { id: panelId } }
      );
      if (!panelData.data) {
        const err = new Error("Wrong response body");
        dispatch(setError(err));
      }
      dispatch(deletePanelData(panelId));
    } catch (e) {
      if (typeof e === "string") {
        const err = new Error(e);
        dispatch(setError(err));
      } else if (e instanceof Error) {
        dispatch(setError(e));
      }
    }
  };
