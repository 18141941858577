import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../index";

export type statusType = "new" | "viewed" | "in-progress" | "done";

export interface IFilterData {
  status?: statusType;
}

export interface IFilters {
  data: IFilterData
}

const initialState: IFilters = {data: {}};

const filterSlice = createSlice({
  name: "filterSlice",
  initialState,
  reducers: {
    setFilterData(state, action: PayloadAction<IFilterData>) {
      state.data = action.payload
    },
  },
});

const { setFilterData } = filterSlice.actions;
export default filterSlice.reducer;

export const setPartialFilters =
  (data: IFilterData) => async (dispatch: AppDispatch) => {
    dispatch(setFilterData(data));
  };
