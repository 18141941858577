import { IPanel } from './types';

export const emptySpecs = {
  maxPower_W: 0,
  voc_V: 0,
  isc_A: 0,
  cells: "MONOCRYSTALLINE",
  vmp_A: 0,
  imp_V: 0,
  operatingTemperature: 0,
  productWarranty_Years: 0,
}

export const emptyDistributor = {
  id: 1,
  companyName: "Fabulous Company SRL",
  phoneNumber: "+231243453",
  location: "Czech Republic,Poland",
  countryCode: "FI",
  description: "We help green this world ",
  address: "str. Korunni 4",
  coverImage: "https://media-exp1.licdn.com/dms/image/C561BAQGU8FxKBc3UOg/company-background_10000/0/1604314322249?e=2147483647&v=beta&t=lpl0vaMZ6AgHNL68hf_X8sOFZte-pJn-v7HR3blqVJ8",
  image: "https://hotline.ua/img/tx/320/3208398545.jpg",
  email: "email@example.test",
};

export const emptyPanelObject: IPanel = {
  id: 0,
  egcType: "SOLAR_PANEL",
  picture: "",
  description: "",
  price: 0,
  specs: emptySpecs,
  distributorId: 0,
  distributor: emptyDistributor,
  codeName: "",
}

export const initEmptyPanel = (distributorId: number) => {
  return {...emptyPanelObject, distributorId , distributor: {...emptyDistributor, id: distributorId}}
}
