
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Copyright } from './Login';
import { AVAILABLE_REGIONS } from '../../../constants/regions';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import { registerDistributor } from '../../../store/reducer/distributorSlice';
import { useAppDispatch, useAppSelector } from '../../../store/storeHooks';
import { Navigate } from 'react-router-dom';

const SignUp = () => {
  const dispatch = useAppDispatch();
  const currentDistributor = useAppSelector((state) => state.distributor.data.content);

  // useEffect(() => {
  //   if (currentDistributor) {
  //     console.log("currentDistributor", currentDistributor);
  //     navigate(-1);
  //   }
  // }, [currentDistributor]);

  const [regions, setRegions] = useState<string[]>([]);

  const handleRegionsChange = (e: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = e;
    const arrValue = typeof value === "string" ? value.split(",") : value;
    setRegions(arrValue);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    for (let pair of data.entries()) {
      console.log(pair[0]+ ', ' + pair[1]);
    }
    registerDistributor(data)(dispatch);
  };

  return currentDistributor ? (
    <Navigate to={"/"} replace />
  ) : (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="organization"
                name="companyName"
                required
                fullWidth
                id="companyName"
                label="Company Name"
                autoFocus
              />
            </Grid>
            {/*<Grid item xs={12} sm={6}>*/}
            {/*  <TextField*/}
            {/*    required*/}
            {/*    fullWidth*/}
            {/*    id="lastName"*/}
            {/*    label="Last Name"*/}
            {/*    name="lastName"*/}
            {/*    autoComplete="family-name"*/}
            {/*  />*/}
            {/*</Grid>*/}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="regions-label">Regions</InputLabel>
                <Select
                  sx={{minWidth: "100%"}}
                  multiple
                  labelId={"regions-label"}
                  label="Regions"
                  id="location"
                  name="location"
                  value={regions}
                  onChange={handleRegionsChange}
                >
                  {AVAILABLE_REGIONS.map((country) => (
                    <MenuItem key={country} value={country}>{country}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{width: "100%"}}
                multiline
                id={"description"}
                label="Description"
                name={"description"}
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="phoneNumber"
                label="Phone Number"
                name="phoneNumber"
                autoComplete="phone"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="address"
                label="Address"
                name="address"
                autoComplete="address"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox name="isEmailSignUp" value="true" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}

export default SignUp;
