import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../index";
import { ContentLoading, initialContentLoading } from "../commonTypes";
import { IDistributor } from '../../content/dashboards/Products/types';
import { emptyDistributor } from '../../content/dashboards/Products/constants';
import { client } from '../../api/AxiosInstance';

export interface IDistributorData {
  data?: ContentLoading<null | IDistributor>;
}

const initialState = (): IDistributorData => {
  const savedDistributorString = localStorage.getItem("currentBoostecoDistributor");
  if (savedDistributorString) {
    const savedDistributor = JSON.parse(savedDistributorString);
    return { data: initialContentLoading(savedDistributor) }
  }

  return { data: initialContentLoading(null) };
};

const distributorSlice = createSlice({
  name: "distributorSlice",
  initialState,
  reducers: {
    startGetDistributor(state) {
      state.data = {
        content: null,
        status: "loading",
        error: undefined,
      };
    },
    startUpdateDistributor(state) {
      state.data = {
        content: state.data.content,
        status: "loading",
        error: undefined,
      };
    },
    changeDistributorData(state, action: PayloadAction<Partial<IDistributor>>) {
      state.data = {
        content: {...state.data.content, ...action.payload},
        status: "loaded",
        error: undefined,
      };
    },
    setDistributorData(state, action: PayloadAction<IDistributor>) {
      state.data = {
        content: action.payload,
        status: "loaded",
        error: undefined,
      };
    },
    setError(state, action: PayloadAction<Error>) {
      state.data = {
        content: null,
        status: "error",
        error: action.payload,
      };
    },
    signOut(state) {
      state.data = {
        content: null,
        status: "init",
        error: undefined,
      };
    },
  },
});

const { startUpdateDistributor, changeDistributorData, setDistributorData, setError, signOut } = distributorSlice.actions;
export default distributorSlice.reducer;

export const registerDistributor =
  (data: FormData) => async (dispatch: AppDispatch) => {
    try {
      const result = await client.post<IDistributor>(
        "/api/dist/newDistributor",
        data
      );
      console.log("result", result);
      if (!(result.status === 200)) {
        const err = new Error("Wrong response body");
        dispatch(setError(err));
      }
      const distributor = initialContentLoading(emptyDistributor);
      dispatch(setDistributorData(distributor.content));
    } catch (e) {
      if (typeof e === "string") {
        const err = new Error(e);
        dispatch(setError(err));
      } else if (e instanceof Error) {
        dispatch(setError(e));
      }
    }
}

export const loginToDistributor =
  (data: FormData) => async (dispatch: AppDispatch) => {
    try {
      // const distributor = await client.post<IDistributor>(
      //   "/api/login",
      //   data
      // );
      // if (!distributor.data) {
      //   const err = new Error("Wrong response body");
      //   dispatch(setError(err));
      // }
      const distributor = initialContentLoading(emptyDistributor);
      dispatch(setDistributorData(distributor.content));
    } catch (e) {
      if (typeof e === "string") {
        const err = new Error(e);
        dispatch(setError(err));
      } else if (e instanceof Error) {
        dispatch(setError(e));
      }
    }
  }

export const updateDistributorData =
  (distributorData: Partial<IDistributor>) => async (dispatch: AppDispatch) => {
    // const distributor = await client.post<IDistributor>(
    //   "/api/editDistributor",
    //   data
    // );
    // if (!distributor.data) {
    //   const err = new Error("Wrong response body");
    //   dispatch(setError(err));
    // }
  dispatch(changeDistributorData(distributorData));
};

export const distributorSignOut = () => (dispatch: AppDispatch) => {
  localStorage.removeItem("isBoostecoDistributorRemember");
  localStorage.removeItem("currentBoostecoDistributor");
  dispatch(signOut());
}
