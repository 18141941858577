import { combineReducers } from "@reduxjs/toolkit";
import panelReducer from "./panelSlice";
import currentPanelReducer from "./currentPanelSlice";
import distributorSlice from './distributorSlice';
import filterReducer from "./filterSlice";

const rootReducer = combineReducers({
  filters: filterReducer,
  panels: panelReducer,
  currentPanel: currentPanelReducer,
  distributor: distributorSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
